.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.big-padding-dialog .mat-dialog-container {
  padding: 48px;

  .mat-dialog-actions {
    margin-top: 32px;
    margin-bottom: 0;
    padding: 0;
    min-height: auto;
  }
}
