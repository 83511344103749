@use '@angular/material' as mat;
@import 'helpers/infragistics';
// CSS Reset, comment out if not required or using a different module
@import 'helpers/reset';

@import 'pendo';

// Helpers
@import 'helpers/parameters';
@import 'helpers/table';
@import 'helpers/stepper';
@import 'helpers/tile';
@import 'helpers/headings';
@import 'helpers/helpers';


@import 'ui-kit/styles/palette';
@import 'ui-kit/styles/typography';

@import '@angular/cdk/overlay-prebuilt.css';

// Components
@import 'components/sortable-mat-list';
@import 'components/mat-dialog-modifiers';
@import 'components/product-wizard-questions';
@import 'components/checkmark-list';

// UI-kit
@import '../src/app/shared/ui-kit/square-button/square-button';

// Layout
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

p {
  margin-bottom: 1rem;
}

html {
  line-height: 1.3;
  /* Transparent tap color iOS */
  -webkit-tap-highlight-color: rgba(#000, 0);
}

html,
body {
  height: 100%;
}

body {
  overflow: hidden;
  font: 16px / 1 $font-families;
  background-color: $color-bg;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.2s;
  text-align: start;
}

button {
  font: inherit;
}

input {
  font: 14px / 2 $font-families;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

iframe {
  border: none;
}

a {
  color: $color-petrol;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: inherit;
  }
}

em {
  font-weight: bold;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #828282;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

mark {
  background-color: transparent;
  font-weight: bold;
  padding: 0;
}

uikit-dialog-container>* {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  overflow: auto;
}

.selector-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.2 !important;
  background-color: #000;
  transition: none;
}

.selector-panel {
  box-shadow: 0px 20px 18px 1px rgba(0, 0, 0, 0.16)
}


.invalidated {
  @include shimmer;
  filter: blur(8px);
  opacity: 0.4;
}

.pink-color {
  color: $color-pink;
}

.spacer {
  flex: 1 1 auto;
}

#error-container>.error-message {
  color: $color-bad-default;
}

@font-face {
  font-family: insight-icons;
  font-style: normal;
  src: url('/assets/fonts/icomoon.woff') format('woff');
}

@font-face {
  font-family: mark;
  font-style: normal;
  src: url('/assets/fonts/MarkProBook.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: mark;
  font-weight: bold;
  src: url('/assets/fonts/MarkProBold.woff2') format('woff2');
  font-display: swap;
}


.pin-login {
  label {
    color: $color-white !important;
  }
}

pin-logon-container {
  font-family: mark;
}

.insight-button {
  font-weight: lighter !important;
  background-color: $color-pink !important;
  color: $color-white !important;
  border-radius: 40px !important;
  padding: 0 60px !important;
  line-height: 40px !important;
}

.insight-button[disabled] {
  opacity: 0.7;
}

.material-icons.md-14 {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.material-icons.md-18 {
  font-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.mat-button-wrapper .material-icons.md-36 {
  line-height: 36px !important;
  height: 36px;
  width: 36px;
}

/* Angular Material overrides */
$insight-typography: mat.define-typography-config($font-family: 'mark, Helvetica, Arial, sans-serif',
  );
@include mat.all-component-typographies($insight-typography);
@include mat.core();

mat-dialog-actions.mat-mdc-dialog-actions.mdc-dialog__actions {
  padding: 8px 24px 24px 24px;
}

.mat-button,
.mat-fab,
.mat-mini-fab,
.mat-raised-button {
  font-family: mark !important;
}

.mat-sort-header-button {
  font-size: 0.875rem !important;
  font-weight: bold !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $color-petrol-lightest;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $color-petrol-light;
}


.cdk-overlay-pane {
  max-width: auto !important;

  &.filter-dropdown-groups-selector,
  &.filter-dropdown-GroupFilter {
    width: 100%;
    max-width: 834px;
  }
}

.fullscreen-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.mat-dialog-container {
  border-radius: 4px;
}

.container-fluid {
  padding: 0;
}

.mat-button:focus,
button:focus {
  outline: none !important;
}

.mat-tab-label,
.mat-tab-group {
  font-family: mark !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
  background-color: inherit;
}

.mat-expansion-panel-content {
  font-family: mark !important;
}

.dy {
  padding: 16px 0 16px 32px !important;
}

.mat-expansion-panel-header {
  padding: 10px 22px !important;
}

.mat-horizontal-content-container {
  overflow: visible !important;
  padding: 0 !important;
}

.nav-link {
  padding: 0.44rem 0.5rem;
  font-weight: lighter !important;
  font-family: mark;
}

.button-logout {
  font-size: 0.75rem;
}

.button-container {
  button {
    margin-right: 8px;
  }
}

/* fix for card in card in result overview header -- start */

@media #{$breakpoint-web} {
  app-page-item-team-latest-header .card-container app-team-latest-header .card-container .header {
    margin: 0 0 24px 0;
  }
}

/* fix for card in card in result overview header -- end */

app-group-filter {
  .mat-tab-header {
    border: hidden !important;
  }

  .mat-tab-label {
    height: auto;
    padding: 0;
    opacity: 1;
    min-width: 0;
    margin-right: 8px;
  }

  mat-ink-bar {
    display: none;
  }
}

snack-bar-container.assertive {
  background-color: $color-bad-default;
}

app-header {
  header {
    .header-menu {
      .header-top-menu {
        .mat-tab-header-pagination {
          box-shadow: unset;
        }
      }
    }
  }
}

app-about-dialog {
  .mat-expansion-indicator::after {
    color: black;
    font-family: 'insight-icons';
    font-size: 24px;
    content: '\e948';
    border: none !important;
    transform: none !important;
  }

  .mat-dialog-container {
    padding: 0 20px;
  }
}

@media #{$breakpoint-tablet, $breakpoint-web} {
  .modal-question .question-content {
    font-size: 1.25rem !important;
    color: #000;
  }

  .interaction {
    padding-top: 50px !important;
    align-items: flex-start !important;
  }
}

// Extra small mobile (iPhone 5/se)
@media only screen and (max-width: 330px) {
  .interaction {
    .survey-question-options {
      div {
        margin: 0 1px !important;
      }
    }
  }
}

// print helper
.print-only {
  display: none !important;
}

@media print {
  .print-only {
    display: initial !important;
  }

  body * {
    visibility: hidden;
    transition: none !important;
    text-shadow: none;
    box-shadow: none;
  }

  .printable,
  .printable * {
    visibility: initial;
  }

  button {
    visibility: hidden;
  }

  .section-to-hide,
  .section-to-hide * {
    visibility: hidden;
  }

  .printable {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    overflow: visible !important;
    page-break-after: auto;
    page-break-inside: auto;
  }
}

.ui-textarea {
  font: inherit;
  background: transparent;
  color: currentColor;
  outline: none;

  width: 100%;
  max-width: 364px;
  padding: 12px;

  border: 1px solid #000;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;

  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: $color-grey3;
  }
}

.ui-input {
  font: inherit;
  background: transparent;
  color: currentColor;
  outline: none;

  width: 100%;
  max-width: 364px;
  padding: 12px;

  border: 1px solid #000;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;

  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: $color-grey3;
  }
}

.mat-snack-bar-container {
  &.multi-line {
    white-space: pre-wrap;
  }
}

.ui-kit-dropdown {

  .e-dropdownbase .e-list-item,
  .e-selectall-parent span.e-all-text {
    font-family: 'mark';
  }

  .e-checkbox-wrapper.e-css .e-frame.e-check {
    background-color: black;
  }
}

mat-chip-list-wrapper {
  width: 100%;
}

/* CDK  overlay */
.cdk-overlay-backdrop.dark-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.5;
  background-color: #000;
}

html.cdk-global-scrollblock {
  overflow-y: hidden;
}

app-organization {
  .side-header .title-container ui-card-subtitle {
    color: $color-grey4 !important;
  }

  ui-card .card-container {
    max-width: none !important;
  }

  app-org-tree-grid .table-modifiers uikit-form-field {
    max-width: 600px;
    width: 100%;
    display: block;

    .uikit-flex-wrapper {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

app-module-selection .module-card-layout .uikit-icon {
  font-size: 30px;
  color: white;
}

app-delete-confirmation .loading-animation-spinner,
app-org-user-grid .loading-animation-spinner,
app-org-tree-grid .loading-animation-spinner,
app-group-selector-v2 .loading-animation-spinner,
.uikit-button.inverted .animation-container,
.group-selector-header .page-title button {
  svg {
    -webkit-filter: invert(100%) !important;
    /* safari 6.0 - 9.0 */
    filter: invert(100%) !important;
  }
}

g[aria-labelledby] {
  display: none !important;
}

app-group-move-dialog {
  .uikit-flex-wrapper {
    padding: 0;
  }

  .red {
    color: $color-pink;
  }
}

app-survey-layout app-content {
  .header h3 {
    display: block;
    margin-bottom: 1rem;

    font-size: 1.375rem;
    line-height: 1.75rem;
    text-align: center;
    font-weight: bold;
  }

  .body {
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media #{$breakpoint-small-handset} {
  app-survey-layout app-content {
    .header h3 {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .body {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

@media #{$breakpoint-web} {
  app-survey-layout app-content {
    .header h3 {
      font-size: 28px;
      line-height: 28px;
    }

    .body {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 1rem;
    }
  }
}

app-survey-layout app-chapter-summary-page app-content {
  .header h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 36px;
  }
}

app-survey-layout app-chapter-page app-content {
  h1 {
    font-size: 1.875rem;
    line-height: 2.375rem;
    font-weight: bold;
  }

  span.body {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }

  uikit-icon {
    margin-bottom: 16px;
  }
}

app-group-move-grab-drop-dialog {
  .mat-dialog-content {
    max-height: 55vh;
  }
}

@media #{$breakpoint-web} {
  app-survey-layout app-chapter-page app-content {
    h1 {
      font-size: 2.5rem;
      line-height: 3.25rem;
      font-weight: bold;
    }

    span.body {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    uikit-icon {
      margin-bottom: 32px;
    }
  }
}

.dialog-title-highlighted {
  color: $color-pink;
}

.skeleton {
  @include shimmer;
}

$droplist-shadow-transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
$droplist-transform-transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

.insight-droplist.cdk-drop-list {
  &.cdk-drag-animating {
    transition: $droplist-shadow-transition;
  }

  &.cdk-drop-list-dragging {
    background-color: $color-grey6;

    .insight-dropitem:not(.cdk-drag-placeholder) {
      transition: $droplist-transform-transition;
    }
  }
}

.insight-dropitem.cdk-drag {
  transition: $droplist-transform-transition;

  .draghandle {
    width: 40px;
    height: 40px;
    cursor: move;
    align-items: center;
    justify-content: center;
  }

  &.cdk-drag-preview {
    box-shadow: 0 0 5px black;
  }
}

.single-icon {
  &:before {
    font-family: 'insight-icons';
    content: '\e91a';
    font-size: 18px;
    margin-right: 8px;
  }
}

/* Mat menu */
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 168px;
  font-size: 0.875rem !important;

  uikit-icon {
    font-size: 1.25rem;
  }
}

.dialog-content {
  display: block;
  height: 100%;
  max-height: 85vh;
  margin: 0 -24px;
  padding: 0 24px;
  overflow: auto;
}

.dialog-actions {
  position:fixed;
  bottom:0;
  display: flex;
  width: 100%;
  column-gap: 16px;
  padding: 8px 0;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: content-box;
  background: #fff;
  z-index: 100;
}

.confirmation-snackbar {
  text-align: center;
}