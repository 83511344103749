$icon-size: 32;
$tile-x: 218;
$tile-y: 125;
$border-width: 2;

ui-radio-group {
  display: flex;
  flex-wrap: wrap;
}

[ui-tile] {
  &.ui-button span.label {
    padding-left: 16px;
    color: $ui-card-title-color;
    font-weight: initial;
  }

  &.ui-radio-button {
    box-shadow: 0px 1.55699px 15.5699px rgba(0, 0, 0, 0.16);
    transition: 0.2s;

    &.ui-radio-checked {
      .ui-radio-container {
        color: #ffffff;
        background-color: #000000;
      }
    }

    &:not(.ui-radio-disabled) {
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:active {
        background-color: #000;
        color: #fff;
      }
    }

    &[ui-tile-small] {
      box-shadow: none;
      border: #{($border-width)}px solid #000;
      .ui-radio-container {
        width: auto;
        height: 44px;

        .ui-radio-label-content {
          flex-direction: row;
          text-transform: none;
          font-size: 1rem;
          letter-spacing: normal;
          padding: 0 24px 0 16px;
        }
      }
    }

    &[ui-tile-xs] {
      box-shadow: none;
      border: #{($border-width)}px solid #000;
      .ui-radio-container {
        text-transform: none;
        width: auto;
        height: 32px;
      }
    }

    .ui-radio-container {

      width: $tile-x + px;
      height: $tile-y + px;
      background-color: #ffffff;
      color: #000000;
      border: 0;

      .ui-radio-label-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        white-space: nowrap;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1.1px;
        word-break: break-all;
        padding: 16px;
      }
    }

  }

  &.ui-button,
  ui-tile-selector & {
    border: 0;
    margin-right: 24px;
    width: $tile-x + px;
    height: $tile-y + px;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1.1px;
    margin-bottom: 20px;
    color: black;
    text-decoration: none;
    border-radius: 0;
    box-shadow: 0;

    .ui-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  ui-tile-selector & {
    background-color: #fff;
    box-shadow: 0 3px 22px 9px rgba(193, 193, 193, 0.17);
  }
}
