.fill-remaining-space {
  
    /* This fills the remaining space, by using flexbox. 
       Every toolbar row uses a flexbox row layout. */
    flex: 1 1 auto;
  }
  .fix-clickable-height {
    .mat-list-text {
      height: 100%;
      a.mat-line {
        height: 100%;
        line-height: 50px;
      }
    }
  }
  
  .tooltip-14 {
    font-size: 14px !important;
    margin:16px;
  } 
  
  .css-spinner {

      /* A spinner similar to Angular's but pure css, to use when javascript can't be used due to single thread issues */

    align-self: center;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: black;
    animation: spin 1s ease-in-out infinite;
  }
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  @mixin trim($numLines: null){
    @if $numLines != null {
       display:-webkit-box;
       -webkit-line-clamp:$numLines;
       -webkit-box-orient:vertical; 
       overflow:hidden;
    }
    @else{
       text-overflow:ellipsis;
       white-space:nowrap;
       overflow:hidden;
       display:block;
    }
 }