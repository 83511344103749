@use 'ui-kit/styles/palette';
@import '../button/button-base';

.ui-kit-square-button {
  @include button-base;
  font-size: 0.875rem;
  display: flex;
  gap: 8px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  font-weight: unset;
  transition: box-shadow 0.3s ease-in-out;

  height: 32px;

  >span {
    line-height: 1;
  }

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  }

  &:active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:focus-visible:not(.ui-kit-not-focusable) {
    outline: 2px solid palette.$color-black;
  }

  &--selected {
    border: 1px solid palette.$color-black;
  }

  &:disabled,
  &[disabled] {
    background: palette.$color-gray6 !important;
    color: palette.$color-gray3 !important;

    cursor: unset;
    pointer-events: none;
  }

  &.ui-kit-square-button-small {
    height: 32px;
    font-size: .875rem;
    min-width: unset;
  }

  &.ui-kit-square-button-big {
    height: 40px;
    font-size: .875rem;
    min-width: unset;
    background-color: palette.$color-petrol-light;
    color: #fff;
  }

  &.ui-kit-square-button--skeleton {
    background: palette.$color-grey5;
    color: palette.$color-grey5;
    border: none;
    pointer-events: none;
    width: 120px;
  }

  & uikit-icon {
    font-size: 1rem;
  }
}
