

  .mat-list-draggable {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
    width: 800px;
    margin-bottom: 25px;

    .mat-list-item.mat-list-item-draggable {
        padding: 20px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        font-size: 14px;   
        &:last-child {
            border: none;
        }
        .mat-list-item-content {
            width: 100%;
            justify-content: space-between;
        }
      }
  }
  
  .cdk-drag-placeholder {
    opacity: 0;
  }
  
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  

  
  .mat-list-draggable.cdk-drop-list-dragging .mat-list-item-draggable:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  .cdk-drag-preview.mat-list-item-draggable {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
            
                .mat-list-item-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 16px;
                    position: relative;
                    height: inherit;
                    width: 100%;
                    justify-content: space-between;
                    .mat-list-text, .mat-ripple {
                        display: none;
                    }
                }

  }

