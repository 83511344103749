@import 'ui-kit/styles/palette';

table {
  width: 100%;
}

td {
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}

.row {
  margin: 0;
  width: 100%;
  justify-content: space-between;
}

.insight-table {
  th {
    padding: 0 24px 12px 24px;
    border-bottom: 1px solid $color-gray2;
  }

  tr {
    background-color: white;
  }

  tr:hover:not(.header-row) {
    filter: brightness(0.95);
  }

  td {
    padding: 24px;
    line-height: 1.25rem;
    border-bottom: 1px solid $color-grey5;
  }
}

.mat-table {
  margin-bottom: 50px;
  font-family: mark;

  tr.mat-footer-row,
  tr.mat-row {
    height: 56px;
  }

  thead th {
    border-color: #000;
  }

  &.outline tr th:first-of-type,
  &.outline tbody tr td:first-of-type {
    border-right: 1px solid #bdbdbd;
  }

  tr th:nth-of-type(2),
  tr td:nth-of-type(2) {
    padding-left: 16px;
  }

  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 32px;
  }
}

tr.mat-row:hover {
  background-color: #e0e0e0;
}

.mat-row {
  height: $ui-table-row-height;
  padding: 0;
  background-color: $ui-table-row-even;
  transition: 0.1s;

  .condensed & {
    height: $ui-table-condensed-row-height;
  }

  &:hover {
    cursor: default;
    background-color: $ui-table-row-even;

    .mat-cell {
      color: inherit;
    }
  }

  &.row-with-details {
    position: relative;
    overflow: initial;

    &:not(.expanded) {
      cursor: pointer;
    }

    &:not(.expanded):hover {
      background: #f5f5f5;
    }

    &.expanded {
      border-bottom-color: transparent;
      background-color: black !important;
      height: auto;

      .mat-cell {
        color: white !important;
      }
    }
  }

  .span-like-link {
    color: $brand-petrol-light;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $brand-petrol-lighter;
    }
  }
}

.mat-table.selectable {
  .mat-row:hover {
    color: $ui-table-hover-even-cell !important;
    background-color: $ui-table-hover !important;
    cursor: pointer !important;
  }
}

.detail-row {
  height: auto;
  overflow: hidden;

  .mat-table {
    height: 100%;
    margin: 0;
    border-bottom: 2px solid #000;
  }

  width: 100%;
}

.mat-cell {
  font-size: 1rem;
  color: $ui-table-row-even-cell;
}

.mat-header-cell {
  font-size: 0.875rem;
  font-weight: bold;
  font-family: mark;
  color: #000;
}

.mat-header-row {
  min-height: 32px;
  border-bottom-width: 2px;
  border-bottom-color: #000;
}

.mat-footer-row,
.mat-header-row,
.mat-row {
  border-bottom-width: 0;
}

.mat-sort-header-button {
  font-size: 0.825rem;
  font-weight: bold;
}

.mat-table>.mat-row {
  border-bottom: 1px solid $ui-table-row-border;
}

.striped {

  mat-header-row,
  .mat-header-row {

    mat-header-cell,
    .mat-header-cell {
      font-weight: bold;
    }
  }

  &>.mat-row {
    border: 0;
  }

  &>.mat-row:nth-child(even) {
    background-color: $ui-table-row-odd;

    .mat-cell {
      color: $ui-table-row-odd-cell;
    }

    &:hover {
      background-color: $ui-table-row-odd;
      cursor: default;

      .mat-cell {
        color: $ui-table-row-odd-cell;
      }
    }
  }
}

.search-input-wrapper {
  position: relative;
  display: inline-block;
  height: 40px;

  i {
    position: absolute;
    right: 8px;
    top: 8px;
    transition: 0.5s;

    &.collapsable {
      cursor: pointer;
    }
  }

  input {
    box-sizing: border-box;
    width: 200px;
    height: 40px;
    color: black;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out, color 0.4s cubic-bezier(1, 0.01, 1, 0.45);

    &.collapsed {
      width: 0;
      padding: 20px 20px;
      transition: all 0.3s ease-in-out, color 0.1s linear;

      &::placeholder {
        transition: all 0.3s ease-in-out;
      }
    }

    &::placeholder {
      transition: all 0.1s ease-in-out;
    }
  }

  .table-filter-input {
    background-color: rgb(230, 230, 230);
    border: 0;
    border-radius: 20px;
    outline: none;
    font-size: 1rem;

    &.collapsed::placeholder,
    &.collapsed {
      color: rgb(230, 230, 230);
    }

    &-simple {
      font-size: 1.25rem;
      border: 0 solid #000;
      border-bottom-width: 3px;
      padding: 10px;
      outline: none;

      &:focus+i {
        color: #aaa;
      }
    }
  }
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border: none !important;
}
