// stylelint-disable declaration-no-important, selector-list-comma-newline-after
@import 'helpers/_parameters';

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1rem;
  font-family: 'mark',
    Helvetica,
    Arial,
    sans-serif;
  font-weight: bold;
  line-height: 1;

  em {
    color: $color-pink;
    font-weight: bold;
    font-style: normal;
  }
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.25rem;

  &+p {
    margin-top: -8px;
  }
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.625rem;
}

h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;

  &.label {
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #828282;
  }
}

h3:has(+ h5.label) {
  margin-bottom: 0.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
}

dt {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

dd {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}


@media #{$breakpoint-web} {
  dt {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: bold;
    margin-bottom: 0.375rem;
  }

  dd {
    font-size: 1rem;
    line-height: 1.625rem;
    margin-bottom: 0.625rem;
  }
}

//
// Horizontal rules
//

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #828282;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
