.ui-h1 {
    font-weight: bold;
    font-size: 1.875rem;
    margin-bottom: 1rem;
}
.ui-h2 {
    font-weight: bold;
    font-size: 1.375rem;
    margin-bottom: 1rem;
}
.ui-h3 {
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}
.ui-h4 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}
.ui-h5 {
    font-weight: bold;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

@media #{$breakpoint-web} {
  .ui-h1 {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .ui-h2 {
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .ui-h3 {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .ui-h4 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .ui-h5 {
    font-weight: bold;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}
