@import 'helpers/_parameters';
.mat-step-header {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mat-stepper-horizontal,
.mat-stepper-vertical{
  background-color: inherit;
}

.sidebar-closed {
  .insight-stepper {
    .mat-horizontal-stepper-header-container {
      left: 64px;
    }
  }
}

.insight-stepper {
  padding-bottom: 70px;

  .mat-step-label {
    font-size: 0.75rem;
    text-transform: capitalize;
    letter-spacing: 0.9px;
  }
  .mat-horizontal-stepper-header-container {
    position: sticky;
    top:0;
    margin-bottom: 48px;
    
    background-color: white;
    z-index: 950;
    transition: 0.2s;
    flex-wrap: wrap;
    
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.03);

    .mat-horizontal-stepper-header {

      .mat-step-icon {
        background-color: white;
        border: 2px solid $brand-petrol;
        color: $brand-petrol;
        font-weight: bold;
        &.mat-step-icon-state-edit {
          background-color: $brand-petrol;
          border: none;
          color: white;
          font-weight: normal;
        }
        &.mat-step-icon-selected {
          background-color: $brand-petrol;
          border: none;
          color: white;
        }
      }
      .mat-step-text-label {
        font-weight: bold;
      }
      &:before {
        border-top-color: $brand-petrol;
        border-top-width: 2px !important;
        width: calc(50% - 9px) !important;
      }
      &:after {
        border-top-color: $brand-petrol;
        border-top-width: 2px !important;
        width: calc(50% - 11px) !important;
      }
      .mat-step-header-ripple {
        display: none;
      }
    }
    .mat-stepper-horizontal-line {
      border-top-color: $brand-petrol;
      border-top-width: 2px;
    }
  }
}
