.aftonbladet {
  ul {
    list-style: none;
    font-size: var(--aftonbladet-font-size, 12px);

    li {
      display: flex;
      align-items: center;
      margin-top: var(--aftonbladet-margin, 8px);
      &:before {
        font-family: 'insight-icons';
        content: '\e912';
        font-size: 18px;
        margin-right: 8px;
      }
    }
  }
}