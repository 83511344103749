// @use "sass:map";
@use "@infragistics/igniteui-angular/theming" as *;
// @use "@infragistics/igniteui-angular/lib/core/styles/themes" as *;
@use "parameters" as *;
@use "ui-kit/styles/palette" as *;

$app-palette: palette($primary: #000000, $secondary: #828282, $surface: #ffffff);

@include core($print-layout: false);
@include theme($palette: $app-palette);

$igx-grid-theme: grid-theme($cell-active-border-color: $color-primary,
    $cell-selected-background: transparent,
    $cell-selected-within-background: transparent,
    $cell-selected-text-color: $color-black,
    $cell-selected-within-text-color: $color-black,
    $row-hover-background: $color-grey6,
    $row-selected-background: $color-petrol-super-duper-light,
    $row-selected-text-color: $color-black,
    $row-selected-hover-background: $color-petrol-super-duper-light,
    $row-border-color: $color-grey5,
    $header-background: $color-primary,
    $header-text-color: $color-black,
    $expand-icon-color: $color-black,
    $expand-icon-hover-color: $color-black,
    $grid-shadow: none,
    $row-odd-background: $color-white,
    $row-even-background: $color-white,
    $header-border-width: 0,
    $header-border-color: transparent,
    $content-background: $color-primary,
  );

$igx-grid-paginator: paginator-theme($text-color: $color-grey2,
    $background-color: transparent,
    $border-color: transparent,
  );

$igx-select: select-theme($toggle-button-background: $color-primary,
    $toggle-button-background-focus: $color-primary,
  );

@include grid($igx-grid-theme);
@include css-vars($igx-grid-theme);
@include css-vars($igx-grid-paginator);
@include css-vars($igx-select);

$igx-calendar-theme: calendar-theme($header-background: $color-petrol-light,
    $content-background: #fdfdfd,
    $header-foreground: $color-black,
    $date-current-background: #fdfdfd,
    $date-current-foreground: $color-black,
    $year-hover-foreground: $color-black,
    $year-hover-background: #fdfdfd,
    $year-current-foreground: $color-black,
    $year-current-background: #fdfdfd,
    $month-current-foreground: $color-black,
    $month-hover-foreground: $color-black,
    $picker-foreground: $color-black,
    $picker-hover-foreground: $color-black,
    $picker-background: #007a73,
    $border-radius: 0,
    $date-border-radius: 0,
    $month-year-border-radius: 0,
    $current-outline-color: $color-black,
    $year-current-hover-foreground: $color-black,
    $year-current-hover-background: #fdfdfd,
    $current-outline-hover-color: #fdfdfd,
    $current-outline-focus-color: #fdfdfd,
    $year-selected-foreground: #000000,
    $year-selected-background: #fdfdfd,
    $year-selected-hover-foreground: #000000,
    $year-selected-hover-background: #fdfdfd,
    $year-selected-current-hover-foreground: #000000,
    $year-selected-current-hover-background: #fdfdfd,
    $selected-outline-color: #000000,
    $selected-focus-outline-color: #000000,
    $selected-hover-outline-color: #fdfdfd,
    $selected-current-outline-color: #fdfdfd,
    $year-selected-current-foreground: #000000,
    $year-selected-current-background: #fdfdfd,
    $selected-current-outline-hover-color: #fdfdfd,
    $date-border-color: #e0e0e0,
    $date-selected-background: #005e58,
    $date-selected-foreground: #ffffff,
    $date-range-border-radius: none,
    $date-selected-range-foreground: #ffffff,
    $date-selected-range-background: #4D9F9A,
    $date-special-range-foreground: #ffffff);

// @include calendar($igx-calendar-theme);
@include css-vars($igx-calendar-theme);

$igx-time-picker-theme: time-picker-theme($border-radius: 0,
    $header-background: $color-petrol-dark,
  );
@include time-picker($igx-time-picker-theme);

$custom-switch-theme: switch-theme($thumb-on-color: white,
    $track-off-color: transparent,
    $track-on-color: $color-petrol,
    $track-disabled-color: transparent,
    $track-on-disabled-color: $color-gray4,
    $thumb-disabled-color: transparent,
    $thumb-on-disabled-color: white,
    $hover-shadow: none,
    $resting-shadow: none,
    $disabled-shadow: none,
    $border-hover-color: $color-petrol,
    $border-disabled-color: $color-gray4 );
@include css-vars($custom-switch-theme);

$toolbar-theme: grid-toolbar-theme($background-color: $color-primary);
@include grid-toolbar($toolbar-theme);

$input-group-theme: input-group-theme($disabled-bottom-line-color: transparent,
    $idle-bottom-line-color: transparent);
@include css-vars($input-group-theme);
@include css-vars($igx-calendar-theme);

.igx-days-view__date,
.igx-days-view__label {
  height: 40px;
}

.igx-days-view__date-inner {
  width: 100%;
  font-size: 0.875rem;
  height: 40px;
}

.igx-days-view__date--range .igx-days-view__date-inner {
  height: 40px;
  ;
}

.igx-calendar__header {
  display: none;
}

.igx-grid {
  box-shadow: none;
}

.igx-grid-thead__wrapper .igx-grid__tr {
  border-bottom: 1px solid $color-black;
}

.igx-grid__td--active {
  box-shadow: none !important;
}

.igx-grid-th__title {
  font-weight: bold;
  opacity: 1 !important;
}

.igx-grid-th__title,
.igx-grid-th,
.igx-grid__td {
  font-family: $font-families;
  font-style: normal;
  font-size: 0.875rem;
  color: $color-black;

  &>span {
    line-height: 1.3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.igx-grid__tr:hover .menu-button {
  visibility: visible !important;

  .uikit-button-wrapper {
    background-color: transparent !important;
  }
}

.igx-grid__drag-indicator {
  cursor: grab;
}

.igx-grid__cbx-selection {
  border-right: none;

  .igx-checkbox__composite {
    margin-bottom: 0;
  }
}

.igx-grid-toolbar,
.igx-grid-toolbar--cosy,
.igx-grid-toolbar--compact {
  border-bottom: none;
}

.igx-grid-thead-thumb {
  display: none;
}

/* cosy */
.igx-grid--cosy {

  .igx-grid-th__title,
  .igx-grid-th,
  .igx-grid__td {
    font-size: 16px;
    line-height: 1.5rem;
  }
}

.igx-grid-th--cosy {
  padding: 0 1.5rem;
}

.igx-grid-th--cosy .igx-grid-th__title {
  flex-grow: 0;
  line-height: 2.5rem;
}

.igx-grid-th--cosy .igx-grid-th__icons {
  justify-content: flex-start;
  flex-grow: 1;
}

.igx-grid-thead__item .igx-grid-th--cosy {
  gap: 8px;
}

.igx-grid--cosy .igx-grid__td {
  padding: 0 1.5rem;
  overflow: hidden;
  white-space: nowrap;
}

.igx-grid__td-text {
  line-height: 1.5;
}

/* end cosy */

.igx-grid__tfoot {
  border-top: 0 !important;
}

.igx-grid-th--pinned-last,
.igx-grid__td--pinned-last {
  border-right: none !important;
}

.igx-grid__td--pinned.igx-grid__td--selected {
  background: white !important;
}

.igx-grid-thead__wrapper {
  border-bottom: 0 !important;
  background: $color-primary !important;
  color: black !important;
}

.igx-page-size__label {
  margin-bottom: 0;
}

.igx-grid__group-content {
  font-size: 0.875rem;
  font-weight: bold;
  color: #000;
}

// "Items per page" selector
igx-paginator .igx-page-size__select {
  min-width: 4rem;
  width: 4rem;

  .igx-input-group__bundle {
    --size: 2rem; // sets the height
    background-color: #fff;

    &-start {
      --ig-spacing-small: 0.6;
    }

    &-main {
      padding-right: 0;
      font-size: 14px;
    }

    &-end {
      --ig-spacing-inline-small: 0.2;
    }
  }

  .igx-input-group__bundle-start,
  .igx-input-group__bundle-end {
    border-radius: 0;
  }

}

.igx-paginator--crammed .igx-page-nav {
  justify-content: flex-start;

  >* {
    margin-left: 0;
    width: auto;
    height: auto;

    &:first-child,
    &:last-child {
      display: none;
    }
  }
}

.igx-overlay__wrapper {
  z-index: 1000 !important;
}

$tabs-theme: tabs-theme($item-background: inherit,
    $item-hover-background: inherit,
    $item-active-background: inherit,
    $item-active-color: #000,
    $indicator-color: #000,
  );
@include tabs($tabs-theme);

.igx-tabs__header-button {
  box-shadow: unset;

  &:hover {
    background: transparent;
  }

  &:focus {
    background: transparent;
  }
}

.igx-tabs.push-tabs-left {
  .igx-tabs__header-button:first-of-type {
    margin-left: -16px;
  }
}

.igx-tabs.push-tabs-left-more {
  .igx-tabs__header-button:first-of-type {
    margin-left: -48px;
  }
}

.igx-tabs__header-scroll {
  gap: 16px;
}

.igx-tabs__header-item {
  min-width: auto;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.2;
  padding: 0;
  margin-right: 0 !important;
}

.igx-tabs__header-item--selected {
  font-weight: bold;
}

.igx-tabs__header-active-indicator {
  min-width: auto;
}

.igx-tabs__header {
  min-height: 2.125rem;
  margin-bottom: 16px;
}

.highlighted {
  .igx-grid-thead {
    padding-left: 10px;
  }
}

app-group-permissions-dialog {
  .igx-grid__tbody-message {
    padding-top: 1rem;
  }
}

app-org-deleted-groups-grid,
app-org-orphan-groups-grid,
app-org-user-grid {
  .uikit-flex-wrapper {
    padding-top: 8px;
    max-width: 350px;
  }

  .igx-grid-thead__thumb {
    border-left: none;
  }

  .igx-checkbox__composite {
    width: 1em;
    height: 1em;
    min-width: 1em;
  }

  .igx-grid__tr--selected {
    background: $color-grey6 !important;

    .igx-grid__td--pinned.igx-grid__td--selected {
      background: $color-grey6 !important;
    }
  }

  .igx-grid__tr--selected .igx-grid__td--selected {
    background: $color-grey6 !important;
  }
}

app-group-move-dialog,
app-move-users-dialog {
  .igx-grid-thead__thumb {
    border-inline-start: 0px !important;
  }
}

app-org-deleted-groups-grid,
app-org-orphan-groups-grid,
app-org-user-grid,
app-org-tree-grid,
app-group-move-dialog,
app-move-users-dialog,
app-connect-history-dialog {

  .igx-grid-toolbar,
  .igx-grid-toolbar--cosy,
  .igx-grid-toolbar--compact {
    --ig-spacing-inline-small: 1.5;

    min-height: 1.5rem;

    button {
      min-width: 2.5rem;
      width: 2.5rem;
      padding: 0.0625rem 0rem;
    }
  }

  .igx-grid-th__title {
    --header-size: calc(8 / 3); // 2.666...
    flex-grow: 0;
    padding-right: 1rem;
  }

  .igx-grid-th__icons {
    flex: 1;
    justify-content: flex-start;
    height: auto;
    align-self: center;
  }

  .igx-grid-thead {
    border-bottom: 1px $color-black solid;
  }

  .igx-grid__tr-action:last-of-type {
    border-right: none;
  }

  .igx-grid.compact .igx-grid-th {
    padding: 0 0.25rem;
  }

  .igx-grid.compact .igx-grid__td {
    padding: 0 0.2rem;
  }

  .igx-grid.compact .igx-grid__drag-indicator {
    padding: 0 0.25rem;
    min-width: 2.25rem;
  }

  .igx-button--outlined {
    background: transparent;
    color: $color-black;
    border: 1px solid transparent;
    border-color: transparent;
  }

  .activeRow {
    background: $color-petrol-lightest !important;
  }

  .childRow {
    background: $color-grey7 !important;

    &:hover {
      background: $color-grey6 !important;
    }
  }

  .parentRow {
    background: $color-grey6 !important;

    &:hover {
      background: $color-grey6 !important;
    }
  }
}

.igx-grid__tr--ghost {
  pointer-events: none;
}

.dragGhost {
  background: #ffffff !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  cursor: grabbing;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: 200px !important;
}

.valid-drop {
  border: 1px solid black !important;
}

.invalid-drop {
  opacity: 0.5;
}

.igx-grid.no-borders {
  .igx-grid-thead {
    border-bottom: none !important;
  }

  .igx-grid__tr {
    border-bottom: none !important;
  }
}

.igx-grid.odd-even {
  .igx-grid__tr--odd {
    background-color: $color-grey6;
  }
}

.igx-grid-th__resize-handle {
  border-width: 7px 4px;

  igx-grid-header-group:hover & {
    background: $color-grey5;
  }
}

.igx-days-view__date--range-preview .igx-days-view__date-inner {
  color: #000000;
  background-color: #e5f1f0;
}

.igx-days-view__date--range.igx-days-view__date--selected .igx-days-view__date-inner {}

.igx-days-view__date--selected.igx-days-view__date--special.igx-days-view__date--range .igx-days-view__date-inner::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #005e58;
  transform: rotate(45deg);
  right: -4px;
  top: -1px;
  z-index: 1;
}

.igx-days-view__date--selected.igx-days-view__date--special.igx-days-view__date--range .igx-days-view__date-inner:hover::after {
  border-color: transparent;
  border-bottom-color: #005e58;
}

.igx-days-view__date--range.igx-days-view__date--selected.igx-days-view__date--last .igx-days-view__date-inner {
  font-weight: bold;
}

.igx-calendar__body {
  display: flex;
  justify-content: space-between;
}

.igx-calendar-picker {
  margin: 8px;

  .igx-calendar-picker__dates {
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: capitalize;
  }
}

.igx-days-view {
  gap: 0px;
  width: 468px;
  margin: 0 0 32px 0;
  padding-bottom: 0;
}

// igx date/time picker -- start
igx-date-picker {
  .igx-input-group__bundle {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
    height: auto;

    igx-picker-clear[igxSuffix] {
      display: none;
    }

    igx-suffix,
    [igxSuffix] {
      padding: 0;
      color: black;

      uikit-icon.block {
        width: 40px;
        height: 40px;
        border-left: 1px solid black;
        padding: 0 5px;
      }
    }
  }

  .igx-input-group__input {
    height: 42px;
  }

  &.disabled {
    opacity: 0.5;

    input {
      color: black;
    }
  }
}

.igx-overlay__wrapper--modal {
  z-index: 1001;

  .igx-date-picker {
    border-radius: 0;

    .igx-calendar {
      .igx-calendar__header {
        background-color: $color-petrol;
      }
    }
  }
}

.time-picker-panel {
  display: flex;
  justify-content: flex-end;
}

.igx-checkbox__composite {
  border-color: $color-black;
  border-radius: 0;

  .igx-checkbox__composite-mark {
    background: $color-black;
  }
}

.igx-checkbox--indeterminate .igx-checkbox__composite::after {
  background: $color-black;
}

.igx-checkbox--indeterminate .igx-checkbox__composite,
.igx-checkbox--checked .igx-checkbox__composite {
  background: $color-black;
  border-color: $color-black;
}

.igx-input-group--disabled:hover {

  &.igx-input-group,
  .igx-input-group__input:hover,
  .igx-input-group__file-input:hover,
  .igx-input-group__textarea:hover {
    cursor: default;
  }
}

.column-header span.igx-grid-th__title {
  white-space: normal;
  line-height: 1rem;
  word-break: initial;
  text-align: center;
}

.igx-hover-item {
  visibility: hidden;
}

.igx-grid__tr:hover .igx-hover-item {
  visibility: visible;
}

igx-tree-grid.index-list-grid {

  .igx-grid__td--pinned-first,
  .igx-grid-th--pinned-first {
    border-left: 1px solid $color-grey6 !important;
  }
}

app-survey-participants-section {
  .igx-grid__tbody-content {
    min-height: 200px;
  }
}

app-send-sms-dialog,
app-send-email-dialog,
app-exclude-from-survey-dialog {
  .red {
    color: $color-pink;
  }
}

igx-grid.fancy {
  --header-size: 40px;

  .igx-grid__td--pinned-first,
  .igx-grid-th--pinned-first {
    border-inline-start: none !important;
  }

  .igx-grid__td--pinned,
  .igx-grid__td--pinned-last,
  .igx-grid__td--pinned-first,
  .igx-grid-th--pinned {
    background-color: inherit;
  }

  .igx-grid-th {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .igx-grid-th__title,
  .igx-grid-th {
    font-size: 0.875rem;
  }

  .igx-grid-th__title {
    font-weight: normal;
  }

  igx-display-container:focus * {
    outline: none !important;
  }

  igx-grid-header {
    /*Sort header icon before title */
    flex-direction: row-reverse;

    .igx-grid-th__icons {
      min-width: 1.25rem;
      justify-content: center;
    }

  }

  .igx-grid-th--active {
    box-shadow: none;

    .igx-grid-th {
      border-inline-end: none;
      border-bottom-color: transparent;
    }
  }

  .igx-grid__tbody-content {
    background-color: transparent;
  }

  .igx-grid__tr {
    margin: 8px 8px 8px 0;
    border: 0;
    border-radius: 4px;
    background-color: #fff;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    overflow: hidden;
    transition: opacity 0.2s;

    &.pendingRow {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .igx-grid__td {
    color: #000;
    font-size: 0.875rem;
    line-height: 1.2;
    font-style: normal;
  }

  .igx-grid__td,
  .igx-grid-th {
    padding-inline-start: 16px;
    padding-inline-end: 16px;

    &.hover {
      cursor: pointer;
    }
  }

  .igx-grid-thead {
    margin-bottom: 4px;
  }

  .igx-grid-thead:focus-visible {
    outline: none !important;
  }

  .igx-grid-thead__item .igx-grid-th {
    padding-inline-start: 3px;
    justify-content: flex-start;
  }

  .igx-grid-thead__wrapper {
    background-color: transparent !important;

    .igx-grid__tr {
      filter: none;
      background-color: transparent;
      margin: 0;
    }


    .igx-grid__tr {
      igx-display-container {
        box-shadow: none;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .igx-grid-th__resize-handle {
    border: transparent;
    background-color: transparent;
    border-width: 7px 4px;

    &:hover {
      background-color: #e1e1e1;
    }
  }

  .igx-grid__scroll {
    background-color: transparent;
  }

  .igx-grid__footer {
    .igx-paginator,
    .igx-paginator--compact {
      background-color: transparent;
      border: none;
      padding: 0 !important;
    }
  }

  .igx-grid-toolbar {
    background-color: transparent;
    padding-inline-start: 0;
    padding-inline-end: 0;
    min-height: 0;
    margin-top: 8px;

    .igx-grid-toolbar__actions {
      margin-inline-start: 0;
      gap: 8px;
    }
  }

  .name-col {
    display: flex;
    gap: 16px;
    overflow: hidden;
    align-items: center;

    span.name,
    span.date {
      line-height: 1.5;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

  }

  .name-col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .noname {
      color: $color-grey3;
    }


    span.created {
      font-size: 0.625rem;
      line-height: 0.75rem;
      color: $color-grey3;

    }
  }

  .label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .product-name {
    color: #828282;
  }

  .latest-update-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }

  .igx-grid-edit-col {
    margin-left: auto !important;
    min-width: initial !important;
    max-width: initial !important;
    flex-basis: initial !important;
  }

  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: $color-petrol-lightest;
    border-radius: 4px;

    uikit-icon {
      font-size: 1.5rem;
    }
  }
}

// Switch
igx-switch {
  .igx-switch__composite {
    width: 1.875rem;
  }

  .igx-switch__composite-thumb {
    width: 0.875rem;
    height: 0.875rem;
    min-width: 0.875rem;
    transform: translateX(-0.125rem);
  }

  .igx-switch__composite,
  .igx-switch__composite-thumb {
    border: 2px solid $color-petrol;
  }

  &:hover {
    opacity: 75%;
  }
}

igx-switch.igx-switch--disabled {

  .igx-switch__composite,
  .igx-switch__composite-thumb {
    border: 2px solid $color-gray4;
  }
}
