/*** Parameters ***/
$select-border-radius: 4px;
$sidebar-width-open: 280px;
$sidebar-width-collapsed: 64px;

// Colors
$color-primary: #ffffff;
$color-bg: #f2f2f2;

$brand-black: #363636;
$brand-blacker: #292929;
$brand-blackest: #000000;

$brand-petrol-variant: #dfeeec;
$brand-petrol-lightest: #cce3e2;
$brand-petrol-lighter: #569d97;
$brand-petrol-light: #00756f;
$brand-petrol: #00756e;
$brand-petrol-gradient-end: #a0dab3;
$brand-petrol-dark: #00635d;
$brand-petrol-lightest-new: #c1dddb;

$brand-pink-light: #ea719e;
$brand-pink: #f04e98;
$brand-pink-dark: #e23c7a;

$brand-red: #f42737;

$brand-orange: #ff6a13;

$brand-yellow: #ffd800;

$brand-green: #00a139;

$brand-invalid: #ca0f27;
$brand-valid: #21a90c;

$brand-grey-light: #989697;
$brand-grey: #868485;
$brand-grey-lighter: #b6b6b6;

$brand-grey-1: #000000;
$brand-grey-2: #4f4f4f;
$brand-grey-3: #828282;
$brand-grey-4: #bdbdbd;
$brand-grey-5: #e0e0e0;
$brand-grey-6: #f2f2f2;
$brand-grey-7: #ffffff;

$brand-result-red: #fb091f;
$brand-result-orange: #ff8200;
$brand-result-yellow: #ffe400;
$brand-result-mint-green: #8fe5a1;
$brand-result-green: #16aa4e;
$brand-result-blue: #1f4ae9;

$brand-grey-variant: #e6e6e6;

$brand-blue-light: #22a6c8;
$brand-blue: #004275;
$brand-dark-blue: rgba(0, 66, 117, 0.25);

$ui-table-row-border: #f2f2f2;
$ui-table-row-odd: #f2f2f2;
$ui-table-row-even: #fff;
$ui-table-hover: #e6e6e6;

$ui-table-row-odd-cell: #000;
$ui-table-row-even-cell: #000;
$ui-table-hover-even-cell: #000;
$ui-table-hover-odd-cell: #000;

$ui-table-row-height: 60px;
$ui-table-condensed-row-height: 48px;
$ui-table-row-height-condensed: 40px;

$page-title-color: #4f4f4f;

$ui-card-title-color: #000000;
$ui-card-subtitle-color: #9f9f9f;

$ui-button-disabled-border: #cecece;
$ui-button-disabled-color: #d7d7d7;
$ui-button-disabled-bg: #e6e6e6;
// Font Size

// Spacing

// Media queries
$breakpoint-handset: '(max-width: 599px) and (orientation: portrait), (max-width: 959px) and (orientation: landscape)';
$breakpoint-handset-portrait: '(max-width: 599px) and (orientation: portrait)';
$breakpoint-handset-landscape: '(max-width: 959px) and (orientation: landscape)';

$breakpoint-tablet: '(min-width: 600px) and (max-width: 839px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279px) and (orientation: landscape)';
$breakpoint-tablet-portrait: '(min-width: 600px) and (max-width: 839px) and (orientation: portrait)';
$breakpoint-tablet-landscape: '(min-width: 960px) and (max-width: 1279px) and (orientation: landscape)';

$breakpoint-web: '(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)';
$breakpoint-web-portrait: '(min-width: 840px) and (orientation: portrait)';
$breakpoint-web-landscape: '(min-width: 1280px) and (orientation: landscape)';

// Media queries
$breakpoint-small-handset: '(max-width: 320px)';
$breakpoint-handset: '(max-width: 599px)';
$breakpoint-handset-portrait: '(max-width: 599px)';
$breakpoint-handset-landscape: '(max-width: 959px) and (orientation: landscape)';

$breakpoint-tablet: '(min-width: 600px) and (max-width: 839px)';
$breakpoint-tablet-portrait: '(min-width: 600px) and (max-width: 839px)';
$breakpoint-tablet-landscape: '(min-width: 960px) and (max-width: 1279px) and (orientation: landscape)';

$breakpoint-web: '(min-width: 600px) ';
$breakpoint-web-portrait: '(min-width: 600px)';
$breakpoint-web-landscape: '(min-width: 600px)';

// Font Family
$font-families: 'mark',
Helvetica,
Arial,
sans-serif;
$sans-serif: 'mark',
sans-serif;

// Animation
$anime-in: 0.4s;
$anime-out: 0.5s;

$sidebar-in-out: 0.3s;

$survey-width: 540px;


// z-index

$z-index-layout-header: 1010;
$z-index-sidebar: 990;
$z-index-sidebar-sub: 980;

$z-index-sidebar-toggle-button: 1030;

// Skeleton
@mixin shimmer {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0));
    animation: shimmer 1s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
